import {Promise} from "es6-promise";
import 'es6-promise'

const axios = require('axios');

const instance = axios.create({
    // localBaseURL: 'http://127.0.0.1:8000/api',
    // onlineBaseURL: 'https://epsasport-dev.apps.lu/api',
    // baseURL: 'https://epsasport-dev.apps.lu/api',
    baseURL:  process.env.VUE_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    }
})
const teacher_store =  {
    namespaced: true,
    state: {
        teacher:{
            id: -1,
            name: '',
            email: ''
        },
        allStoreTeachers: '',
        allStoreTeachersPagination:[],
        meta:{},
    },
    mutations: {
        setTeachers: function (state, teacher){
            state.allStoreTeachers = teacher.data;
        },
        setTeachersPagination: function (state, teacher){
            state.allStoreTeachersPagination = teacher.data;
            state.meta = teacher.meta;
        }

    },
    actions: {
        getTeacherList: ({commit}) => {
            const config = {
                headers:{
                    Authorization : 'Bearer '+localStorage.getItem('token')
                }
            };
            return new Promise((resolve, reject) => {
                instance.get('/teacher/list', config)
                    .then(function (response){
                        commit('setTeachers', response.data);
                        resolve(response);
                    })
                    .catch(function (error){

                        commit('setTeachers', 'error_get_teachers');
                        reject(error);
                    });
            });
        },
        getTeacherListPagination: ({commit}, data) => {
            const config = {
                headers:{
                    Authorization : 'Bearer '+localStorage.getItem('token')
                }
            };
            return new Promise((resolve, reject) => {
                instance.get(`/teacher/listPagination?page=${data.page}`, config)
                    .then(function (response){
                        commit('setTeachersPagination', response.data);
                        resolve(response);
                    })
                    .catch(function (error){

                        commit('setTeachers', 'error_get_teachers');
                        reject(error);
                    });
            });
        },

        saveTeacher: ({commit}, teacherInfos) => {
            const config = {
                headers:{
                    Authorization : 'Bearer '+localStorage.getItem('token')
                }
            };
            return new Promise((resolve, reject) => {
                commit;
                instance.post('/teacher/register',teacherInfos, config)
                    .then(function (response){
                        commit('setTeachers', response.data);
                        resolve(response);
                    })
                    .catch(function (error){
                        reject(error);
                    });
            });

        },
        importFile: ({commit}, teacherInfos) => {
            const config = {
                headers:{
                    Authorization : 'Bearer '+localStorage.getItem('token')
                }
            };
            return new Promise((resolve, reject) => {
                commit;
                instance.post('/teachers/import',teacherInfos, config)
                    .then(function (response){
                        resolve(response);
                    })
                    .catch(function (error){
                        reject(error);
                    });
            });

        },
        deleteTeacher: ({commit}, teacherInfos) => {
            const config = {
                headers:{
                    Authorization : 'Bearer '+localStorage.getItem('token')
                }
            };
            return new Promise((resolve, reject) => {
                commit;
                instance.delete('/teacher/delete/'+teacherInfos.teacher_id, config)
                    .then(function (response){
                        commit('setTeachers', response.data);
                        resolve(response);
                    })
                    .catch(function (error){
                        console.log(error, localStorage.getItem('token'))
                        reject(error);
                    });
            });

        },
    }
}

export default teacher_store;

