<template>
  <div >
    <!-- Modal -->
    <div v-if="openClose" class="modal fade show index-1000"
         tabindex="-1" aria-labelledby="modalLabel" aria-modal="true" role="dialog"
         style="display:block">
      <div class="modal-dialog modal-dialog-top">
        <div class="modal-content">
          <div class="modal-header">
          </div>
          <div class="modal-body">
            <slot></slot>
          </div>
          <div class="modal-footer">
            <button type="button"  @click="openCloseFun()" :class="'btn btn-'+variant" >Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "ModalMessage",
  props:{
    openClose: {
      type: Boolean,
      default: false
    },
    variant:String,
    msg:String
  },
  mounted() {
  },
  data() {
    return {
    }
  },
  methods:{
    openCloseFun(){
      this.$emit('close')
    }
  }
}

</script>
<style scoped >
.index-1000{
  z-index: 1000;
}
</style>