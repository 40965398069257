<template>
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-8 ml-auto mr-auto">
          <form class="form" method="" action="">
            <div class="card card-login card-hidden">
              <div class="card-header card-header-primary text-center">
                <span class="card-title" v-if="mode == 'login'">Login</span>
                <span class="card-title" v-else>Registration</span>
              </div>
              <div class="card-body ">

                <p class="card-description text-center">
                  <span class="btn btn-secondary" @click="switchToCreateAccount()" v-if="mode == 'login'">Create an account</span>
                  <span class="btn btn-secondary" @click="switchToLogin()" v-else>Do you have an account?</span>
                </p>

                <span class="bmd-form-group" v-if="mode == 'create'">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="material-icons">face</i>
                      </span>
                    </div>
                    <input v-model="name" type="text" class="form-control" placeholder="First Name...">
                  </div>
                </span>
                <span class="bmd-form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="material-icons">email</i>
                      </span>
                    </div>
                    <input type="email" v-model="email" class="form-control" placeholder="Email...">
                  </div>
                </span>
                <span class="bmd-form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="material-icons">lock_outline</i>
                      </span>
                    </div>
                    <input type="password"  v-model="password" class="form-control" placeholder="Password...">
                  </div>
                </span>
                <span class="bmd-form-group" v-if="mode == 'create'">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="material-icons">lock_outline</i>
                      </span>
                    </div>
                    <input type="password" v-model="password_confirmation" class="form-control" placeholder="Confirm Password...">
                  </div>
                </span>
              </div>
              <div class="card-footer justify-content-center">
                <a href="#pablo" @click="login()" class="btn btn-rose btn-link btn-lg" :class="{'disabled' : !validatedFields}" v-if="mode == 'login'">LOGIN</a>
                <a href="#pablo" @click="createAccount()" class="btn btn-rose btn-link btn-lg" :class="{'disabled' : !validatedFields}" v-else>CREATE NEW ACCOUNT</a>
              </div>
              <span class="text-warning" v-if="mode == 'login' && status == 'error_login'">Email or password incorrect...</span>
              <span v-else></span>
            </div>
          </form>
        </div>
      </div>
    </div>

</template>


<script>
import { mapState } from 'vuex'

  export default {
    name: 'LoginView',
    data: function (){
      return{
        mode: 'login',
        email: '',
        name: '',
        password: '',
        password_confirmation: ''
      }
    },
    methods: {
      switchToCreateAccount: function (){
        this.mode = 'create';
      },
      switchToLogin: function (){
        this.mode = 'login';
      },
      login: function (){
        const self = this;

        this.$store.dispatch('login', {
          email: this.email,
          password: this.password
        }).then(function (response){
          self.$router.push('/profile')
          console.log(response);
        }, function (error){
          console.log(error);
        });
      },
      createAccount: function (){
        const self = this;
        this.$store.dispatch('createAccount', {
          email: this.email,
          name: this.name,
          password: this.password,
          password_confirmation: this.password_confirmation
        }).then(function (response){
          self.login();
          console.log(response);
        }, function (error){
          console.log(error);
        });
      }
    },
    computed: {
      validatedFields: function (){
        if(this.mode == 'create'){
          if(this.email != '' && this.name != '' && this.password != '' && this.password == this.password_confirmation){
            return true;
          }
          else return false;
        }
        else{
          if(this.email != '' && this.password != ''){
            return true;
          }
          else return false;
        }
      },
      ...mapState([status])
    },

  }
</script>