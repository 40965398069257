<template>
  <MyNavMenu />
  <div class="features-1" style="background-image: url('/assets/img/bg7.jpg'); background-size: cover; background-position: top center;">

  <div class="container insidebox">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 ml-auto mr-auto" style=" background: rgba(11, 52, 82, 0.64) none repeat scroll 0% 0%;padding: 1em; border-radius: 15px">
          <div v-if="role=='admin'" class="col-lg-3 col-md-4 col-sm-12 ml-auto"  style="float: left;">
            <form class="form">
              <div class="card card-login card-hidden">
                <div class="card-header card-header-primary text-center">
                  <span class="card-title">Classes</span>
                </div>
                <div class="card-body ">
                  <span class="bmd-form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="material-icons">face</i>
                      </span>
                    </div>
                    <input v-model="name" type="text" class="form-control" placeholder="Class Name...">
                  </div>
                </span>

                </div>
                <div class="card-footer justify-content-center">
                  <a @click="saveClassButton()" class="btn btn-rose btn-lg">CREATE NEW CLASS</a>
                </div>

              </div>
            </form>

          </div>
          <div class=""  :class="[role !== 'admin' ? 'col-12' : 'col-lg-8 col-md-8 col-sm-12']" style="float: left">
            <div class="table-responsive">
              <table class="table">
                <thead>
                <tr>
                  <th class="text-center  text-white">#</th>
                  <th class="text-center text-white">Name</th>
                  <th class="text-center text-white">Num Students</th>
                  <th class="text-center text-white">Challenges</th>
                  <th class="text-right text-white">Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in this.$store.state.allStoreClassesPagination" :key="`classes-${item.id}`">
                  <td class="text-center">{{(index+1)+(page-1)*10}}</td>
                  <td class="text-center">{{item.name}}</td>
                  <td class="text-center">{{item.num_student}}</td>
                  <td class="text-center">{{item.num_challenge}}</td>
                  <td class="td-actions text-right">
<!--                    <button @click="openClass(item.id)" type="button" rel="tooltip" class="btn btn-info btn-just-icon btn-sm" style="margin-right: 2px">-->
<!--                      <i class="material-icons">open_in_browser</i>-->
<!--                    </button>-->
<!--                    <button type="button" rel="tooltip" class="btn btn-success btn-just-icon btn-sm" style="margin-right: 2px">-->
<!--                      <i class="material-icons">edit</i>-->
<!--                    </button>-->
                    <button v-if="role=='admin'"  type="button" rel="tooltip"  @click="deleteClasse(item.id, item.name)" class="btn btn-danger btn-just-icon btn-sm" style="margin-left: 2px">
                      <i class="material-icons">close</i>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <v-pagination
                v-if="this.$store.state.classMeta.last_page>1"
                id="pagination"
                v-model="page"
                :pages="this.$store.state.classMeta.last_page"
                :range-size="1"
                active-color="#DCEDFF"
                @update:modelValue="updatePageHandler"
            />
          </div>
          <modal-message @close="closeModal" :variant="variant" :open-close="openClose" >
            <p class="text-center"><svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" :fill="msgColor" class="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
              <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
            </svg></p>
            <p class="text-center h4 text-dark">{{msg}}</p>
          </modal-message>
        </div>
      </div>
    </div>
    </div>

</template>

<script>
import MyNavMenu from "@/components/MyNavMenu";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import ModalMessage from "@/components/ModalMessage";

export default {
  name: "ClassesView",

  components: {
    MyNavMenu,
    VPagination,
    ModalMessage
  },
  data: function (){
    return{
      allClasses: '',
      name:'',
      role: localStorage.role,
      page:1,
      openClose:false,
      msg:"",
      variant:"success",
      msgColor: "#47a44b"
    }
  },
  mounted: function() {
    const self = this;
    if(localStorage.getItem('token') == ''){
      self.$router.push('/admin');
    }

    this.getClassesListPagination(this.page);

  },
  methods: {
    deleteClasse: function(id, name){
      const self = this;
      if(confirm("Do you really want to delete ("+name+") ?")) {
        self.$store.dispatch('deleteClasse', {classe_id: id}).then(function () {
          self.page = 1 ;
          self.getClassesListPagination(self.page);
        }, function () {
        });
      }
    },
    saveClassButton: function (){
      const self = this;
      self.$store.dispatch('saveClassAction', {
        name: this.name
      }).then(function (){
        self.page = 1 ;
        self.getClassesListPagination(self.page);
        self.name=null;

        self.msg= "Class created successfully!";
        self.variant="success";
        self.msgColor="#47a44b";
        self.openClose=true;
      }, function (error){
        self.variant="danger";
        self.msgColor="#dc3545";
        if(error.response&&error.response.data.message){
          self.msg= error.response.data.message;

        } else {
          self.msg='Something went wrong please try again leter';
        }
        self.openClose=true;
      });
    },
    openClass: function (id){
      const url = '/classe/'+id;
      this.$store.state.currentClasse_id = id;
      this.$router.push(url);
    },
    getClassesListPagination(page){
      // const self = this;
      this.$store.dispatch('getClassesListPagination',{page:page}).then(function (){
      }, function (error){
        console.log("No school registered: "+error)
      });
    },
    updatePageHandler(page){
      this.getClassesListPagination(page);
    },
    closeModal(){
      this.openClose=false;
    }
  },

}
</script>

<style scoped>

</style>