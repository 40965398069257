<template>
  <MyNavMenu />
  <div class="features-1" style="background-image: url('/assets/img/bg7.jpg'); background-size: cover; background-position: top center;">

  <div class="container insidebox">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 ml-auto mr-auto" style=" background: rgba(11, 52, 82, 0.64) none repeat scroll 0% 0%;padding: 1em; border-radius: 15px">
          <div v-if="role=='admin'" class="col-lg-3 col-md-4 col-sm-12 ml-auto" style="float: left">

            <form class="form"  autocomplete="off">

              <div class="card card-login card-hidden">
                <div class="card-header card-header-primary text-center">
                  <span class="card-title">Teachers</span>
                </div>
                <div class="card-body ">
                  <span class="bmd-form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="material-icons">face</i>
                      </span>
                    </div>
                    <input v-model="name" name="name" type="text" class="form-control" placeholder="First Name...">
                  </div>
                </span>
                  <span class="bmd-form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="material-icons">email</i>
                      </span>
                    </div>
                    <input type="email" v-model="email" class="form-control" placeholder="Email...">
                  </div>
                </span>

                  <span class="bmd-form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="material-icons">lock_outline</i>
                      </span>
                    </div>
                    <input type="password"  autocomplete="new-password" v-model="password" class="form-control" placeholder="Password...">
                  </div>
                </span>
                  <span class="bmd-form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="material-icons">lock_outline</i>
                      </span>
                    </div>
                    <input type="password" v-model="password_confirmation" class="form-control" placeholder="Confirm Passwords...">
                  </div>
                </span>

                </div>
                <div class="card-footer justify-content-center">
                  <a @click="saveTeacher()" class="btn btn-rose btn-lg">CREATE NEW TEACHER</a>
                </div>

                <loader v-if="loaderActive" :active="loaderActive" message="Data is importing" />
                <div v-else class="mt-3 text-white">
                   <h5 >Import data</h5>
                    <input class="ml-3" type="file"  @change="uploadFile" ref="file">
                    <a @click="importFile()" class="btn btn-rose mt-3">Add data</a>
                </div>
              </div>
            </form>
          </div>
          <div class="" :class="[role !=='admin' ? 'col-12' : 'col-lg-8 col-md-8 col-sm-12']" style="float: left">
            <div class="table-responsive">
              <table class="table">
                <thead>
                <tr>
                  <th class="text-center text-white">#</th>
                  <th class="text-center text-white">Name</th>
                  <th class="text-center text-white">Email</th>
                  <th class="text-right text-white">Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in this.$store.state.teacher_store.allStoreTeachersPagination" :key="`teacher-${item.id}`">
                  <td class="text-center text-white">{{(index+1)+(page-1)*10}}</td>
                  <td class="text-center text-white">{{item.name}}</td>
                  <td class="text-center text-white">{{item.email}}</td>
                  <td class="td-actions text-right">
<!--                    <button type="button" rel="tooltip" class="btn btn-success btn-just-icon btn-sm" style="margin-right: 2px">-->
<!--                      <i class="material-icons">edit</i>-->
<!--                    </button>-->
                    <button v-if="role=='admin'" type="button" rel="tooltip"  @click="deleteTeacher(item.id, item.name)" class="btn btn-danger btn-just-icon btn-sm" style="margin-left: 2px">
                      <i class="material-icons">close</i>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <v-pagination
                v-if="this.$store.state.teacher_store.meta.last_page>1"
                id="pagination"
                v-model="page"
                :pages="this.$store.state.teacher_store.meta.last_page"
                :range-size="1"
                active-color="#DCEDFF"
                @update:modelValue="updatePageHandler"
            />
          </div>
          <modal-message @close="closeModal" :variant="variant" :open-close="openClose" >
            <p class="text-center"><svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" :fill="msgColor" class="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
              <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
            </svg></p>
            <p class="text-center h4 text-dark">{{msg}}</p>
          </modal-message>
        </div>
      </div>
    </div>
    </div>

</template>

<script>
import {mapActions} from "vuex";
import MyNavMenu from "@/components/MyNavMenu";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import ModalMessage from "@/components/ModalMessage";
import Loader from "@/components/Loader.vue";

export default {
  name: "TeacherView",
  components:{
    MyNavMenu,
    VPagination,
    ModalMessage,
    Loader
  },
  data(){
    return {
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
      role: localStorage.role,
      page:1,
      openClose:false,
      msg:"",
      variant:"success",
      msgColor: "#47a44b",
      file: null,
      loaderActive: false,
    }
  },
  computed: {
    ...mapActions( [
        "teacher_store/getTeacherList",
        "teacher_store/deleteTeacher",
        "teacher_store/saveTeacher"
    ]),
  },
  mounted: function() {
    const self = this;
    if(localStorage.getItem('token') == ''){
      self.$router.push('/admin');
    }

    this.getTeacherListPagination(this.page);
  },
  methods: {
   deleteTeacher: function(id, name) {
     const self = this;
     if(confirm("Do you really want to delete ("+name+") ?")) {
     self.$store.dispatch('teacher_store/deleteTeacher', {teacher_id: id}).then(function () {
       self.page = 1;
       self.getTeacherListPagination(self.page);
       console.log('success delete');
     }, function () {
     });
   }
    },
    saveTeacher: function (){
      const self = this;
      self.$store.dispatch('teacher_store/saveTeacher', {
        email: this.email,
        name: this.name,
        password: this.password,
        password_confirmation: this.password_confirmation
      }).then(function (){
        self.page = 1;
        self.getTeacherListPagination(self.page)
        self.name = '';
        self.email = '';
        self.password = '';
        self.password_confirmation = '';

        self.msg= "Teacher created successfully!";
        self.variant="success";
        self.msgColor="#47a44b";
        self.openClose=true;
      }, function (error){
        self.variant="danger";
        self.msgColor="#dc3545";
        if(error.response&&error.response.data.message){
          self.msg= error.response.data.message;
        } else {
          self.msg='Something went wrong please try again leter';
        }
        self.openClose=true;
      });
    },
    getTeacherListPagination(page){
      this.$store.dispatch('teacher_store/getTeacherListPagination',{page:page}).then(function (){
      }, function (error){
        console.log(error)
      });
    },
    updatePageHandler(page){
      this.getTeacherListPagination(page);
    },
    closeModal(){
      this.openClose=false;
    },
    importFile(){
      const self = this;
      let formData = new FormData();
      formData.append('file', this.file);
      this.loaderActive = true;

      self.$store.dispatch('teacher_store/importFile', formData).then(function (){
        self.loaderActive = false;
        self.page = 1;
        self.getTeacherListPagination(self.page);

        self.msg= "Teacher created successfully!";
        self.variant="success";
        self.msgColor="#47a44b";
        self.openClose=true;
      }, function (error){
        self.loaderActive = false;
        self.variant="danger";
        self.msgColor="#dc3545";
        if(error.response&&error.response.data.message){
          self.msg= error.response.data.message;

        } else {
          self.msg='Something went wrong please try again leter';
        }
        self.openClose=true;
      });

    },
    uploadFile(){
      this.file = this.$refs.file.files[0];
    }

  },
}
</script>

<style scoped>

</style>