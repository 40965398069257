import {Promise} from "es6-promise";
import 'es6-promise'

const axios = require('axios');

const instance = axios.create({
    // localBaseURL: 'http://127.0.0.1:8000/api',
    // onlineBaseURL: 'https://epsasport-dev.apps.lu/api',
    // baseURL: 'https://epsasport-dev.apps.lu/api',
    baseURL:  process.env.VUE_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    }
})
const challenge_type_store =  {
    namespaced: true,
    state: {
        allStoreChallengeType: '',
    },
    mutations: {
        setChallengeType: function (state, challenge_type){
            state.allStoreChallengeType = challenge_type.data;
        }
    },
    actions: {
        getChallengeTypeList: ({commit}) => {
            const config = {
                headers:{
                    Authorization : 'Bearer '+localStorage.getItem('token')
                }
            };
            return new Promise((resolve, reject) => {
                instance.get('/challenge_type/list', config)
                    .then(function (response){
                        commit('setChallengeType', response.data);
                        resolve(response);
                    })
                    .catch(function (error){

                        commit('setChallengeType', 'error_get_teachers');
                        reject(error);
                    });
            });
        }
    }
}

export default challenge_type_store;

