<template>
  <div class="features-1" style="background-image: url('/assets/img/bg7.jpg'); background-size: cover; background-position: top center;">

    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-8 ml-auto mr-auto">
          <form class="form" method="" action="">
            <div class="card card-login card-hidden">
              <div class="card-header card-header-primary text-center">
                <span class="card-title">Login Administrator</span>
              </div>
              <div class="card-body ">
                <span class="bmd-form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="material-icons">email</i>
                      </span>
                    </div>
                    <input type="email"  v-model="email" class="form-control" placeholder="Email...">
                  </div>
                </span>
                <span class="bmd-form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="material-icons">lock_outline</i>
                      </span>
                    </div>
                    <input type="password"  v-model="password" class="form-control" placeholder="Password...">
                  </div>
                </span>
              </div>
              <div class="">
                <router-link to="/forgot-password" class="text-white" >Forgot password</router-link>
              </div>
              <div class="card-footer justify-content-center">
                <a @click="login()" class="btn btn-rose btn-lg" :class="{'disabled' : !validatedFields}">Connect</a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    </div>

</template>


<script>
import { mapState } from 'vuex'

export default {
  name: 'LoginAdmin',


  mounted: function() {
    const self = this;
    if(localStorage.getItem('token') != ''){
      self.$router.push('/dashboard');
    }
  },


  data: function (){
    return{
      mode: 'login',
      email: '',
      name: '',
      password: '',
      password_confirmation: ''
    }
  },
  methods: {
    switchToCreateAccount: function (){
      this.mode = 'create';
    },
    switchToLogin: function (){
      this.mode = 'login';
    },
    login: function (){
        const self = this;
      this.$store.dispatch('loginAdmin', {
        email: this.email,
        password: this.password
      }).then(function (){

         self.$router.push('/dashboard')
      }, function (error){
        alert('account or password incorrect');
        console.log(error, 'login error');
      });
    }
  },
  computed: {
    validatedFields: function (){
      if(this.mode == 'create'){
        if(this.email != '' && this.name != '' && this.password != '' && this.password == this.password_confirmation){
          return true;
        }
        else return false;
      }
      else{
        if(this.email != '' && this.password != ''){
          return true;
        }
        else return false;
      }
    },

    ...mapState([status])
  }
  }

</script>