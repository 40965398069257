<template>
  <MyNavMenu />
  <div class="features-1" style="background-image: url('/assets/img/bg7.jpg'); background-size: cover; background-position: top center;">
    <div class="container insidebox">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 ml-auto mr-auto" style=" background: rgba(11, 52, 82, 0.64) none repeat scroll 0% 0%;padding: 1em; border-radius: 15px">
          <div class="col-lg-3 col-md-4 col-sm-12 ml-auto" style="float: left">
            <form class="form">
              <div class="card card-login card-hidden">
                <div class="card-header card-header-primary text-center">
                  <span class="card-title">Students</span>
                </div>
                <div class="card-body ">
                  <span class="bmd-form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="material-icons">face</i>
                      </span>
                    </div>
                    <input v-model="name" type="text" class="form-control" placeholder="First Name...">
                  </div>
                </span>
                  <span class="bmd-form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="material-icons">email</i>
                      </span>
                    </div>
                    <input type="email" v-model="email" class="form-control" placeholder="Email...">
                  </div>
                </span>
                  <span class="bmd-form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="material-icons">lock_outline</i>
                      </span>
                    </div>
                    <input type="password" v-model="password" class="form-control" placeholder="Password...">
                  </div>
                </span>
                  <span class="bmd-form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="material-icons">lock_outline</i>
                      </span>
                    </div>
                    <input type="password" autocomplete="new-password" v-model="password_confirmation" class="form-control" placeholder="Confirm Passwords...">
                  </div>
                </span>
                  <span class="bmd-form-group">
                    <div class="input-group">
                    <span class="input-group-text">
                        <i class="material-icons">school</i>
                      </span>
                    <select class="custom-select" v-model="classe_id" data-style="select-with-transition" title="Choose a Class" data-size="7">
                      <option disabled>Choose class</option>
                      <option v-for="item in this.$store.state.allStoreClasses" v-bind:key="item.id" :value="item.id">
                       {{ item.name }}
                      </option>
                    </select>
                    </div>
                  </span>
                </div>
                <div class="card-footer justify-content-center">
                  <a @click="saveStudent()" class="btn btn-rose btn-lg">CREATE NEW STUDENT</a>
                </div>
                <loader v-if="loaderActive" :active="loaderActive" message="Data is importing" />
                <div v-else class="mt-3 text-white">
                  <h5 >Import data</h5>
                  <input class="ml-3" type="file"  @change="uploadFile" ref="file">
                  <a @click="importFile()" class="btn btn-rose mt-3">Add data</a>
                </div>

              </div>
            </form>
          </div>
          <div class="col-lg-8 col-md-8 col-sm-12" style="float: left">
            <div class="table-responsive">
              <table class="table">
                <thead>
                <tr>
                  <th class="text-center text-white">#</th>
                  <th class="text-center text-white">Name</th>
                  <th class="text-center text-white">Challenges</th>
                  <th class="text-center text-white">Email</th>
                  <th class="text-center text-white">Classe</th>
                  <th class="text-right text-white">Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in this.$store.state.student_store.allStoreStudentsPagination" :key="`student-${item.id}`">
                  <td class="text-center text-white">{{(index+1)+(page-1)*10}}</td>
                  <td class="text-center text-white">{{item.name}}</td>
                  <td class="text-center text-white cursor-pointer" @click="openChallenges(item.id)" >{{item.challenges}}</td>
                  <td class="text-center text-white">{{item.email}}</td>
                  <td class="text-center text-white">{{item.classe.name}}</td>
                  <td class="td-actions text-right">
                    <button type="button" rel="tooltip" class="btn btn-success btn-just-icon btn-sm mr-2" @click="openEditModal(item)">
                      <i class="material-icons">edit</i>
                    </button>
                    <button   type="button" rel="tooltip"  @click="deleteStudent(item.id, item.name)" class="btn btn-danger btn-just-icon btn-sm">
                      <i class="material-icons">close</i>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <v-pagination
                v-if="this.$store.state.student_store.meta.last_page>1"
                id="pagination"
                v-model="page"
                :pages="this.$store.state.student_store.meta.last_page"
                :range-size="1"
                active-color="#DCEDFF"
                @update:modelValue="updatePageHandler"
            />
          </div>
          <modal-message @close="closeModal" :variant="variant" :open-close="openClose" >
            <p class="text-center"><svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" :fill="msgColor" class="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
              <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
            </svg></p>
            <p class="text-center h4 text-dark">{{msg}}</p>
          </modal-message>

          <!-- edit Modal -->
          <div v-if="openEdit" class="modal fade show d-block index-100"
               tabindex="-1" aria-labelledby="modalLabel" aria-modal="true" role="dialog">
            <div class="modal-dialog modal-dialog-top" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <button type="button" class="close" @click="closeEditModal">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <form class="form">
                      <div class="card-body ">
                      <span class="bmd-form-group">
                         <div class="input-group mt-3">
                           <div class="input-group-prepend">
                             <span class="input-group-text">
                               <i class="material-icons text-secondary">face</i>
                             </span>
                           </div>
                           <input v-model="editName" type="text" class="form-control text-secondary" placeholder="First Name...">
                         </div>
                      </span>
                      <span class="bmd-form-group">
                         <div class="input-group mt-3">
                           <div class="input-group-prepend">
                             <span class="input-group-text">
                               <i class="material-icons text-secondary">email</i>
                             </span>
                           </div>
                           <input type="email" v-model="editEmail" class="form-control text-secondary" placeholder="Email...">
                         </div>
                      </span>
                      <span class="bmd-form-group">
                         <div class="input-group mt-3">
                           <div class="input-group-prepend">
                             <span class="input-group-text">
                               <i class="material-icons text-secondary">lock_outline</i>
                             </span>
                           </div>
                           <input type="password" v-model="editPassword" class="form-control text-secondary" placeholder="Password...">
                         </div>
                      </span>
                      <span class="bmd-form-group">
                         <div class="input-group mt-3">
                           <div class="input-group-prepend">
                             <span class="input-group-text">
                               <i class="material-icons text-secondary">lock_outline</i>
                             </span>
                           </div>
                           <input type="password" autocomplete="new-password" v-model="editPasswordConfirmation" class="form-control text-secondary" placeholder="Confirm Passwords...">
                         </div>
                      </span>
                      <span class="bmd-form-group">
                        <div class="input-group mt-3">
                        <span class="input-group-text">
                            <i class="material-icons text-secondary">school</i>
                          </span>
                        <select class="custom-select text-secondary" v-model="editClasseId" data-style="select-with-transition" title="Choose a Class" data-size="7">
                          <option disabled>Choose class</option>
                          <option v-for="item in this.$store.state.allStoreClasses" v-bind:key="item.id" :value="item.id">
                           {{ item.name }}
                          </option>
                        </select>
                        </div>
                      </span>
                      </div>
                  </form>

                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary px-5 mr-2"  @click="closeEditModal">Close</button>
                  <button type="button" class="btn btn-rose px-5" @click="editStudent">Edit</button>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
    </div>

</template>

<script>
import {mapActions} from "vuex";
import MyNavMenu from "@/components/MyNavMenu";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import ModalMessage from "@/components/ModalMessage";
import Loader from "@/components/Loader.vue";

export default {
  name: "StudentView",

  components: {
    MyNavMenu,
    VPagination,
    ModalMessage,
    Loader
  },
  mydata: '',
  data(){
    return{
      name:'',
      email:'',
      password: '',
      password_confirmation:'',
      classe_id: null,
      editStudentId:null,
      editName:'',
      editEmail:'',
      editPassword: '',
      editPasswordConfirmation:'',
      editClasseId: null,
      role: localStorage.role,
      students:[],
      page:1,
      openClose:false,
      msg:"",
      variant:"success",
      msgColor: "#47a44b",
      openEdit: false,
      file: null,
      loaderActive: false,

    }
  },
  mounted: function () {
    const self = this;
    if (localStorage.getItem('token') == '') {
      self.$router.push('/admin');
    }

    this.getStudentListPagination(this.page);

    this.$store.dispatch('getClassesList').then(function (response) {
      self.mydata = response.data.data;
    }, function () {
    });

  },
  methods: {
    deleteStudent: function (id, name) {
      const self = this;
      if(confirm("Do you really want to delete ("+name+") ?")) {
        self.$store.dispatch('student_store/deleteStudent', {student_id: id}).then(function () {
          self.page=1
          self.getStudentListPagination( self.page);
        }, function () {
        });
      }
    },
    saveStudent: function () {
      const self = this;
      self.$store.dispatch('student_store/saveStudent', {
        email: this.email,
        name: this.name,
        password: this.password,
        password_confirmation: this.password_confirmation,
        classe_id: this.classe_id
      }).then(function () {
        self.page = 1 ;
        self.getStudentListPagination( self.page);
        self.email = '';
        self.name = '';
        self.password = '';
        self.password_confirmation = '';
        self.classe_id = null,

        self.msg= "Student created successfully!";
        self.variant="success";
        self.msgColor="#47a44b";
        self.openClose=true;
      }, function (error) {
        self.variant="danger";
        self.msgColor="#dc3545";
        if(error.response&&error.response.data.message){
          self.msg= error.response.data.message;

        } else {
          self.msg='Something went wrong please try again leter';
        }
        self.openClose=true;
      });
    },
    editStudent:function(){
      const self = this;
      self.$store.dispatch('student_store/updateStudent', {
        id: this.editStudentId,
        student: {
          email: this.editEmail,
          name: this.editName,
          password: this.editPassword,
          password_confirmation: this.editPasswordConfirmation,
          classe_id: this.editClasseId
        }
      }).then(function () {
        self.page = 1 ;
        self.getStudentListPagination( self.page);
        self.resetEditModalData();
        self.msg= "Student updated successfully!";
        self.variant="success";
        self.msgColor="#47a44b";
        self.openClose=true;

        self.openEdit=false;
      }, function (error) {
        self.variant="danger";
        self.msgColor="#dc3545";
        if(error.response&&error.response.data.message){
          self.msg= error.response.data.message;
        } else {
          self.msg='Something went wrong please try again leter';
        }
        self.openClose=true;
      });


    },
    getStudentListPagination(page){
      // const staf = this;
      this.$store.dispatch('student_store/getStudentListPagination', {page: page}).then(function () {
      });
    },
    updatePageHandler(page){
      this.getStudentListPagination(page);
    },
    openChallenges(id){
      const url = `/students/${id}/challenges`;
      this.$router.push(url);
    },
    closeModal(){
      this.openClose=false;
    },
    openEditModal(student){
      this.editStudentId = student.id;
      this.editName = student.name;
      this.editEmail = student.email;
      this.editClasseId = student.classe ? student.classe.id : null;

      this.openEdit=true;
    },
    resetEditModalData(){
      this.editStudentId = null
      this.editName = '';
      this.editEmail = '';
      this.editPassword = '';
      this.editPasswordConfirmation = '';
      this.editClasseId = null;
    },
    closeEditModal(){
      this.resetEditModalData();
      this.openEdit=false;
    },
    importFile(){
      const self = this;
      let formData = new FormData();
      formData.append('file', this.file);
      this.loaderActive = true;

      self.$store.dispatch('student_store/importFile', formData).then(function (){
        self.loaderActive = false;
        self.page = 1;
        self.getStudentListPagination(self.page);

        self.msg= "Students created successfully!";
        self.variant="success";
        self.msgColor="#47a44b";
        self.openClose=true;
      }, function (error){
        self.loaderActive = false;
        self.variant="danger";
        self.msgColor="#dc3545";
        if(error.response&&error.response.data.message){
          self.msg= error.response.data.message;

        } else {
          self.msg='Something went wrong please try again leter';
        }
        self.openClose=true;
      });

    },
    uploadFile(){
      this.file = this.$refs.file.files[0];
    }
  },
  computed: {
    ...mapActions([
      "student_store/getStudentList",
      'student_store/deleteStudent',
      'student_store/saveStudent'
      ]),

}
}
</script>

<style>
.index-100{
  z-index: 100;
}
#pagination .Page{
  color: whitesmoke;
}
#pagination .Page-active{
  color: black;
}
</style>