<template>
  <MyNavMenu />
  <div class="features-1" style="background-image: url('/assets/img/bg7.jpg'); background-size: cover; background-position: top center;">

  <div class="container insidebox">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 ml-auto mr-auto" style=" background: rgba(11, 52, 82, 0.64) none repeat scroll 0% 0%;padding: 1em; border-radius: 15px">
        <h1 class="text-center">EPSASPORT DASHBOARD</h1>
      </div>
    </div>
  </div>
  </div>

</template>

<script>
import MyNavMenu from "@/components/MyNavMenu";
export default {

  name: "DashboardView",

  components: {
    MyNavMenu
  },

  mounted: function () {
    const self = this;
    if(localStorage.getItem('token') == ''){
      self.$router.push('/admin');
    }

  }
}
</script>

<style scoped>

</style>