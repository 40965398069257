<template>
  <MyNavMenu />
  <div class="features-1" style="background-image: url('/assets/img/bg7.jpg'); background-size: cover; background-position: top center;">
    <div class="mx-5 insidebox">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 mx-auto" style=" background: rgba(11, 52, 82, 0.64) none repeat scroll 0% 0%;padding: 1em; border-radius: 15px">
          <h1>Profile</h1>
          <div class=" col-10 col-md-6 mx-auto bg-white">
                <form class="form">
                  <div class="card-body ">
                      <span class="bmd-form-group">
                         <div class="input-group mt-3">
                           <div class="input-group-prepend">
                             <span class="input-group-text">
                               <i class="material-icons text-secondary">face</i>
                             </span>
                           </div>
                           <input v-model="editName" type="text" class="form-control text-secondary" placeholder="First Name...">
                         </div>
                      </span>
                    <span class="bmd-form-group">
                         <div class="input-group mt-3">
                           <div class="input-group-prepend">
                             <span class="input-group-text">
                               <i class="material-icons text-secondary">email</i>
                             </span>
                           </div>
                           <input type="email" v-model="editEmail" class="form-control text-secondary" placeholder="Email...">
                         </div>
                      </span>
                    <span class="bmd-form-group">
                         <div class="input-group mt-3">
                           <div class="input-group-prepend">
                             <span class="input-group-text">
                               <i class="material-icons text-secondary">lock_outline</i>
                             </span>
                           </div>
                           <input type="password" v-model="editPassword" class="form-control text-secondary" placeholder="Password...">
                         </div>
                      </span>
                    <span class="bmd-form-group">
                         <div class="input-group mt-3">
                           <div class="input-group-prepend">
                             <span class="input-group-text">
                               <i class="material-icons text-secondary">lock_outline</i>
                             </span>
                           </div>
                           <input type="password" autocomplete="new-password" v-model="editPasswordConfirmation" class="form-control text-secondary" placeholder="Confirm Passwords...">
                         </div>
                      </span>
                  </div>
                </form>
              <div class="modal-footer">
                <button type="button" class="btn btn-rose px-5" @click="editProfile">Edit</button>
              </div>

            <modal-message @close="closeModal" :variant="variant" :open-close="openClose" >
              <p class="text-center"><svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" :fill="msgColor" class="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
              </svg></p>
              <p class="text-center h4 text-dark">{{msg}}</p>
            </modal-message>

          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import MyNavMenu from "@/components/MyNavMenu";
import ModalMessage from "@/components/ModalMessage";

export default {
  name: "ProfileView",
  data(){
    return {
      editName:'',
      editEmail:'',
      editPassword: '',
      editPasswordConfirmation:'',
      msg:"",
      variant:"success",
      msgColor: "#47a44b",
      openClose:false,
    }
  },
  components: {
    MyNavMenu,
    ModalMessage
  },
  mounted: function() {
    this.getProfile();
  },
  methods:{
    getProfile(){
      const self = this;
      this.$store.dispatch('profile',).then(function (response) {
        self.editName = response.data.student ? response.data.student.name : null ;
        self.editEmail = response.data.email;
      }, function () {});
    },
    editProfile(){
      const self = this;
      const user = {
        email: this.editEmail,
        name: this.editName,
        password: this.editPassword,
        password_confirmation: this.editPasswordConfirmation,
      }
      this.$store.dispatch('updateProfile', user).then(function(){
        self.getProfile();
        self.msg= "Profile updated successfully!";
        self.variant="success";
        self.msgColor="#47a44b";
        self.openClose=true;
      }, function (error) {
        self.variant="danger";
        self.msgColor="#dc3545";
        if(error.response&&error.response.data.message){
          self.msg= error.response.data.message;

        } else {
          self.msg='Something went wrong please try again leter';
        }
        self.openClose=true;
      });
    },
    closeModal(){
      this.openClose=false;
    },

  }
}
</script>

<style scoped>

</style>