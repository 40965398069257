<template>
  <MyNavMenu />
  <div class="features-1" style="background-image: url('/assets/img/bg7.jpg'); background-size: cover; background-position: top center;">

    <div class="mx-5 insidebox">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 ml-auto mr-auto" style=" background: rgba(11, 52, 82, 0.64) none repeat scroll 0% 0%;padding: 1em; border-radius: 15px">
          <div class="col-12" style="float: left">
            <div class="table-responsive">
              <table class="table">
                <thead>
                <tr>
                  <th class="text-center text-white">#</th>
                  <th class="text-center text-white">Student Name</th>
                  <th class="text-center text-white">Student Challenge Start</th>
                  <th class="text-center text-white">Student Total Distance</th>
                  <th class="text-center text-white">Student AVG Speed</th>
                  <th class="text-center text-white">Challenge Name</th>
                  <th class="text-center text-white">Challenge distance</th>
                  <th class="text-center text-white">Teacher</th>
                  <th class="text-center text-white">Class</th>
                  <th class="text-center text-white">Participation</th>
                  <th class="text-center text-white">Podium</th>
                  <th class="text-center text-white">Duration From</th>
                  <th class="text-center text-white">Duration To</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item , index) in this.$store.state.student_store.allStoreStudentOwnChallengesPagination" :key="index">
                  <td class="text-center text-white">{{(index+1)+(page-1)*10}}</td>
                  <td class="text-center text-white">{{item.user.student.name}}</td>
                  <td class="text-center text-white cursor-pointer" @click="openDailyChallenges(item.challenge.id)">{{item.started_at}}</td>
                  <td class="text-center text-white cursor-pointer" @click="openDailyChallenges(item.challenge.id)">{{item.total_distance}}</td>
                  <td class="text-center text-white cursor-pointer" @click="openDailyChallenges(item.challenge.id)">{{item.avg_speed}}</td>
                  <td class="text-center text-white" >{{item.challenge.name}}</td>
                  <td class="text-center text-white">{{item.challenge.distance}}</td>
                  <td class="text-center text-white" v-if="item.challenge.teacher != null">{{item.challenge.teacher.name}}</td>
                  <td class="text-center text-white" v-else>None</td>
                  <td class="text-center text-white">
                    <div v-for="(classe, classIndex) in item.challenge.classes" :key="`class-${classIndex}`"  @click="openClass(classe.id)">{{classe.name}}</div>
                  </td>
                  <td class="text-center text-white">{{item.challenge.pts_participation}}</td>
                  <td class="text-center text-white">{{item.challenge.pts_podium}}</td>
                  <td class="text-center text-white">{{item.challenge.duration_from}}</td>
                  <td class="text-center text-white">{{item.challenge.duration_to}}</td>
                </tr>
                </tbody>
              </table>
              <div v-if="!this.$store.state.student_store.allStoreStudentOwnChallengesPagination.length">
                <p>You do not have Challenges.</p>
              </div>
            </div>
            <v-pagination
                v-if="this.$store.state.student_store.metaOwnChallenges.last_page>1"
                id="pagination"
                v-model="page"
                :pages="this.$store.state.student_store.metaOwnChallenges.last_page"
                :range-size="1"
                active-color="#DCEDFF"
                @update:modelValue="updatePageHandler"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import MyNavMenu from "@/components/MyNavMenu";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
  name: "StudentChallengesView",
  data(){
    return {
      challenges: [],
      role: localStorage.role,
      page:1,
    }
  },
  components: {
    MyNavMenu,
    VPagination
  },
  mounted: function() {
    const self = this;
    if(localStorage.getItem('token') == ''){
      self.$router.push('/admin');
    }

    this.getStudentChallengesPagination(this.page);

  },
  methods:{
    getStudentChallengesPagination(page){
      this.$store.dispatch('student_store/getStudentOwnChallengesPagination',{page: page}).then(function (){
      }, function (error){
        console.log(error);
      });
    },
    openClass: function(id){
      if (this.role !== 'student') {
        const url = '/classe/' + id;
        this.$router.push(url);
      }
    },
    openDailyChallenges: function(id){
      const url = `/own/challenges/${id}/daily`;
      this.$router.push(url);
    },
    updatePageHandler(page){
      this.getStudentChallengesPagination(page);
    },
  }
}
</script>

<style scoped>

</style>