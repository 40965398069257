<template>
  <MyNavMenu />
  <div class="features-1" style="background-image: url('/assets/img/bg7.jpg'); background-size: cover; background-position: top center;">

    <div class="mx-5 insidebox">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 ml-auto mr-auto" style=" background: rgba(11, 52, 82, 0.64) none repeat scroll 0% 0%;padding: 1em; border-radius: 15px">
          <div class="col-12" style="float: left">
            <div class="table-responsive">
              <table class="table">
                <thead>
                <tr>
                  <th class="text-center text-white">#</th>
                  <th class="text-center text-white">Student Name</th>
                  <th class="text-center text-white">Student Email</th>
                  <th class="text-center text-white">Class</th>
                  <th class="text-center text-white">Student Finished At</th>
                  <th class="text-center text-white">Total Minute</th>
                  <th class="text-center text-white">Challenge Name</th>
                  <th class="text-center text-white">Challenge Distance</th>
                  <th class="text-center text-white">Teacher</th>
                  <th class="text-center text-white">Challenge Duration To</th>
                  <th class="text-center text-white">Point</th>
                  <th class="text-center text-white">Completed At</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item , index) in this.$store.state.student_store.rankingChallenge" :key="index">
                  <td class="text-center text-white">{{index+1}}</td>
                  <td class="text-center text-white">{{item.user.name}}</td>
                  <td class="text-center text-white">{{item.user.email}}</td>
                  <td class="text-center text-white">{{item.user.classe}}</td>
                  <td class="text-center text-white cursor-pointer" @click="openDailyChallenges(item.user.student_id)">{{dateTime(item.student_finished_at)}}</td>
                  <td class="text-center text-white">{{item.total_min}}</td>
                  <td class="text-center text-white">{{item.challenge.name}}</td>
                  <td class="text-center text-white">{{item.challenge.distance}}</td>
                  <td class="text-center text-white">{{item.challenge.teacher ? item.challenge.teacher.name: ''}}</td>
                  <td class="text-center text-white">{{item.challenge.duration_to}}</td>
                  <td class="text-center text-white">{{item.point}}</td>
                  <td class="text-center text-white">{{item.challenge.completed_at}}</td>
                </tr>
                </tbody>
              </table>
              <div v-if="!this.$store.state.student_store.rankingChallenge.length">
                <p>There are no students who finished this challenge.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import MyNavMenu from "@/components/MyNavMenu";
import moment from 'moment';

export default {
  name: "RankingChallengesView",
  data(){
    return {
      challenges: [],
      role: localStorage.role,
      currentChallengeId: this.$route.params.id,
    }
  },
  components: {
    MyNavMenu
  },
  mounted: function() {
    const self = this;
    if(localStorage.getItem('token') == ''){
      self.$router.push('/admin');
    }

    this.getRankingChallenge();

  },
  methods:{
    getRankingChallenge(){
      this.$store.dispatch('student_store/getRankingChallenge', {challengeId: this.currentChallengeId }).then(function (){
      }, function (error){
        console.log(error);
      });
    },
    dateTime(value) {
      return moment(value).format('YYYY-MM-DD HH:mm:ss');
    },
    openDailyChallenges: function(studentId){
      if(this.role !=='student'){
         const url = `/students/${studentId}/challenges/${this.currentChallengeId}/daily`;
         this.$router.push(url);
      }
    },
  }
}
</script>

<style scoped>

</style>