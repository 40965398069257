<template>
  <MyNavMenu />
  <div class="features-1" style="background-image: url('/assets/img/bg7.jpg'); background-size: cover; background-position: top center;">

    <div class="container insidebox">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 ml-auto mr-auto" style=" background: rgba(11, 52, 82, 0.64) none repeat scroll 0% 0%;padding: 1em; border-radius: 15px">
<!--          <div class="col-lg-3 col-md-4 col-sm-12 ml-auto" style="float: left;">-->

<!--            <div class="row">-->
<!--              <div class="col-md-12">-->
<!--                <div class="form-group">-->
<!--                  <label class="bmd-label-floating">New Class Name</label>-->
<!--                  <input type="text" v-model="name" class="form-control" >-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="row">-->
<!--                <div class="col-md-4 ml-auto mr-auto text-center">-->
<!--                  <button @click="saveClassButton()" class="btn btn-primary btn-raised">Save Class</button>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

<!--          </div>-->
          <div class="col-12" style="float: left">
            {{currentClasseId}}
            <div class="table-responsive">
              <table class="table">
                <thead>
                <tr>
                  <th class="text-center  text-white">#</th>
                  <th class="text-center text-white">Name</th>
                  <th class="text-center text-white">Num Students</th>
                  <th class="text-center text-white">Challenges</th>
                  <th class="text-right text-white">Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td class="text-center">{{classe.id}}</td>
                  <td class="text-center">{{classe.name}}</td>
                  <td class="text-center">{{classe.num_student}}</td>
                  <td class="text-center">{{classe.num_challenge}}</td>
                  <td class="td-actions text-right">
<!--                    <button @click="openClass(item.id)" type="button" rel="tooltip" class="btn btn-info btn-just-icon btn-sm" style="margin-right: 2px">-->
<!--                      <i class="material-icons">open_in_browser</i>-->
<!--                    </button>-->
<!--                    <button type="button" rel="tooltip" class="btn btn-success btn-just-icon btn-sm" style="margin-right: 2px">-->
<!--                      <i class="material-icons">edit</i>-->
<!--                    </button>-->
                    <button v-if="role=='admin'"  type="button" @click="deleteClasse(classe.id, classe.name)" rel="tooltip" class="btn btn-danger btn-just-icon btn-sm" style="margin-left: 2px">
                      <i class="material-icons">close</i>
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import MyNavMenu from "@/components/MyNavMenu";

export default {
  name: "ClasseView",
  data(){
    return {
      classe: [],
      currentClasseId: this.$route.params.id,
      role: localStorage.role
    }
  },
  components: {
    MyNavMenu
  },
  mounted: function() {
    const self = this;
    if(localStorage.getItem('token') == ''){
      self.$router.push('/admin');
    }
    self.$store.dispatch('getClasse',{currentClasseId: this.currentClasseId}).then(function (){
      self.classe = self.$store.state.storeClasse;
    }, function (error){
      console.log("No school registered: "+error)
    });

  },
 methods:{
   deleteClasse: function(id, name){
     const self = this;
     if(confirm("Do you really want to delete ("+name+") ?")) {
       self.$store.dispatch('deleteClasse', {classe_id: id}).then(function () {
         this.$router.push('/classes');
       }, function () {
       });
     }
   },
 }
}
</script>

<style scoped>

</style>