<template>
  <MyNavMenu />
  <div class="features-1" style="background-image: url('/assets/img/bg7.jpg'); background-size: cover; background-position: top center;">

    <div class="mx-5 insidebox">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 ml-auto mr-auto" style=" background: rgba(11, 52, 82, 0.64) none repeat scroll 0% 0%;padding: 1em; border-radius: 15px">
          <div class="col-12" style="float: left">
            <div class="table-responsive">
              <table class="table">
                <thead>
                <tr>
                  <th class="text-center text-white">#</th>
                  <th class="text-center text-white">Daily date</th>
                  <th class="text-center text-white">Distance</th>
                  <th class="text-center text-white">Speed</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item , index) in this.$store.state.student_store.allStoreStudentDailyChallenges" :key="index">
                  <td class="text-center text-white">{{index+1}}</td>
                  <td class="text-center text-white">{{dateTime(item.created_at)}}</td>
                  <td class="text-center text-white">{{item.distance}}</td>
                  <td class="text-center text-white">{{item.speed}}</td>
                </tr>
                </tbody>
              </table>
              <div v-if="!this.$store.state.student_store.allStoreStudentDailyChallenges.length">
                <p>You do not have Daily Challenges.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import MyNavMenu from "@/components/MyNavMenu";
import moment from 'moment';

export default {
  name: "StudentDailyChallengesView",
  data(){
    return {
      challenges: [],
      role: localStorage.role,
      currentChallengeId: this.$route.params.challengeId,
      currentStudentId: this.$route.params.studentId,
    }
  },
  components: {
    MyNavMenu
  },
  mounted: function() {
    const self = this;
    if(localStorage.getItem('token') == ''){
      self.$router.push('/admin');
    }

    this.getStudentChallengesPagination();

  },
  methods:{
    getStudentChallengesPagination(){
      this.$store.dispatch('student_store/getStudentDailyChallenges', {studentId: this.currentStudentId, challengeId: this.currentChallengeId }).then(function (){
      }, function (error){
        console.log(error);
      });
    },
    dateTime(value) {
      return moment(value).format('YYYY-MM-DD HH:mm:ss');
    },
  }
}
</script>

<style scoped>

</style>