<template>
  <div class="features-1" style="background-image: url('/assets/img/bg7.jpg'); background-size: cover; background-position: top center;">

    <div class="container">
      <div class="row">
        <div class="col-12 col-md-8 ml-auto mr-auto">
          <form class="form" method="" action="">
            <div class="card card-login card-hidden">
              <div class="card-header card-header-primary text-center">
                <span class="card-title">Forgot Password</span>
              </div>
              <div class="card-body ">
                <span class="bmd-form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <i class="material-icons">email</i>
                      </span>
                    </div>
                    <input type="email"  v-model="email" class="form-control" placeholder="Email...">
                  </div>
                </span>
              </div>
              <div class="card-footer justify-content-center">
                <a @click="forgotPassword()" class="btn btn-rose btn-lg text-white" :class="{'disabled' : !validatedFields}">Send</a>
              </div>
            </div>
          </form>
        </div>
      </div>
      <modal-message @close="closeModal" :variant="variant" :open-close="openClose" >
        <p class="text-center"><svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" :fill="msgColor" class="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
        </svg></p>
        <p class="text-center h4 text-dark">{{msg}}</p>
      </modal-message>
    </div>
  </div>

</template>


<script>
import { mapState } from 'vuex'
import ModalMessage from "@/components/ModalMessage";


export default {
  name: 'ForgotPassword',


  mounted: function() {
  },


  data: function (){
    return{
      email: this.$route.query.email,
      openClose:false,
      msg:"",
      variant:"success",
      msgColor: "#47a44b"
    }
  },
  components: {
    ModalMessage
  },
  methods: {
    forgotPassword: function (){
      const self = this;
      this.$store.dispatch('forgotPassword', {
        email: this.email,
      }).then(function (){
        self.msg= "We have emailed your password reset link!";
        self.variant="success";
        self.msgColor="#47a44b";
        self.openClose=true;
      }, function (error){
        self.variant="danger";
        self.msgColor="#dc3545";
        if(error.response&&error.response.data.message){
          self.msg= error.response.data.message;
        } else {
          self.msg='Something went wrong please try again leter';
        }
        self.openClose=true;
      });
    },
    closeModal(){
      this.openClose=false;
    }
  },
  computed: {
    validatedFields: function (){
        if(this.email != ''){
          return true;
        }
        else return false;
    },

    ...mapState([status])
  }
}

</script>